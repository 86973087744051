import './poker.css'
import TablePage from './timetable.jsx'
import {ethers} from 'ethers';
import AnimatedPage from '../../utils/AnimatedPage';
import { Button, Spinner } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect, useRef } from 'react'; 
import VeNFTianAbi from "../../../VeNFTian.json";
// import pokerTable from "../../images/pokerTable2.jpeg"
import { MDBBtn } from "mdbreact";
import Snackbar from "./../../utils/snackbar";
import Footer from "../Footer/Footer"
import AOS from 'aos';
import 'aos/dist/aos.css';
import { getConnectedStakingContract, getConnectedERC721Contract } from '../../utils/Contracts/ContractConnections';
import { connectWallet, getCurrentWalletConnected } from "../../utils/interact.js";
import DiamondIcon from "./../../imagesNew/Diamond Icon.png"
import Navbar from "./../Navbar/Navbar"
import Table from "./../../imagesNew/table.png"

AOS.init();

const SnackbarType = {
  success: "success",
  fail: "fail",
};


const VeNFTianERC721 = "0x4ef2dccff801f59b8ef13af218b8e41c06e86a7f";

const Poker = () => {

    const [loading, setLoading] = useState("none");
    const [loading2, setLoading2] = useState("none");
    const [loadingText, setLoadingText] = useState("Link NFT");
    const [loadingText2, setLoadingText2] = useState("Upload User Info");
    const [membershipNumber, setMembershipNumber] = useState("Membership ID")
    const [connectedContract, setConnectedContract] = useState(null);
    const [snackBarType, SetSnackBarType] = useState(SnackbarType.fail)
    const [snackBarMessage, SetSnackBarMessage] = useState("")
    const [discordId, SetDiscordId] = useState("")
    const [clubGGUsername, SetClubGGUsername] = useState("")
    const [clubGGID, SetClubGGID] = useState("")

    const snackbarRef = useRef(null);

    const pokerTable = "https://venftian.s3.eu-west-2.amazonaws.com/pokerTable2.jpeg"

    // useEffect(() => {
    //     getConnectedContract();
    //   }, []);

    function buttonLoad(){
        if(loading == "none"){
            setLoading("border")
            setLoadingText(" Loading")
        }
    }

    async function setDetails(){
        if(loading2 == "none"){
            setLoading2("border")
            setLoadingText2(" Loading")
            setTimeout(() => {
                setLoading2("none")
                setLoadingText2("Upload User Info")
             }, 2900);   
        }
        if(discordId == "" || clubGGUsername == "" || clubGGID == ""){
            SetSnackBarMessage("Details Cannot Be Empty")
            SetSnackBarType(SnackbarType.fail)
            snackbarRef.current.show() 
            setTimeout(() => {
                setLoading2("none")
                setLoadingText2("Upload User Info")
             }, 2900);         
        }

        try{
            const connectedContract = await getConnectedERC721Contract()
            if(!connectedContract) return;
                const userDetails = await connectedContract.setDetails(clubGGUsername, clubGGID, discordId, membershipNumber)
                setLoading("None")
                setLoadingText("Link NFT")
          }catch(err){
            setLoading("None")
            setLoadingText("Loaded")
            // SetSnackBarMessage("Public Sale Locked")
            // SetSnackBarType(SnackbarType.fail)
            // snackbarRef.current.show()  
            console.log(err);
        }
    }

    const getMemebrshipPass = async () => {
        var passNumber = "Not Found";
        var wallet = await (await getCurrentWalletConnected()).address
        setLoading("border")
        setLoadingText(" Loading")

        try{
            const connectedContract = await getConnectedERC721Contract()
            console.log(connectedContract.readAllUsernames());
            console.log(wallet)

            if(!connectedContract) return;
                passNumber = await connectedContract.findTokensOfAddress(wallet)
                setMembershipNumber(parseInt(passNumber[0].toHexString(16), 16))
                setLoading("None")
                setLoadingText("Link NFT")
        }catch(err){
            setTimeout(() => {
            SetSnackBarMessage("No Season Pass Found")
            SetSnackBarType(SnackbarType.fail)
            snackbarRef.current.show()  
            setLoading("none")
            setLoadingText("Link NFT")
         }, 2900); 
          //console.log(err);
        }
    }

    function redirectClubgg(){
        window.open(
            'https://www.clubgg.net/',
            '_blank'
          );
    }

    return(
        <div className='container2'>    
                {/* <AnimatedPage> */} 
            <div className="background-home-section-banner">
                <Navbar/>
                <div className='section-titles'>
                    <div className='how-it-works-section-text'>
                        <div className='page-title'>            
                            <h5>POKER</h5>
                        </div>
                    </div>
                </div>
                <div className='down-arrow'></div>
            </div>
            <Snackbar
                ref={snackbarRef}
                message={snackBarMessage}
                type={snackBarType}
            />
            <div className='grey-bk-section'>
                <div className='claim-prize-section'>                
                    <div className='who-are-we-section'>
                        <div className='who-are-we-section-img-mobile'>
                            <img className='vaultIcon' src={DiamondIcon}></img>
                        </div>
                        <div className='who-are-we-section-text'>
                            <h1>ACTIVATE YOUR MEMBERSHIP</h1>
                            <p>To participate in club freeroll tournaments your membership must be activated and connected to your digital wallet. Follow the steps below by submitting your details and verifying ownership. Ensure that all information is entered accurately, this is important for a seamless prize claim experience.</p>
                        </div>
                        <div className='who-are-we-section-img'>
                            <img className='vaultIcon' src={DiamondIcon}></img>
                        </div>
                    </div>
                </div>
            </div>
            <div className="background-home">
        <div className='advantage-section'>
            <div className='activate-membership-container'>
                <div className='activate-membership-text-holder'>
                    <div>
                        <h1 className='step'>STEP 1</h1>                        
                    </div>
                    <div>
                        <h2>Get the App</h2>
                        <p>Season 1 tournaments are hosted on the ClubGG poker application. Download the app on desktop or mobile and create a free ClubGG account.</p>
                        <button className='landing-container-center-right-signup-btn' onClick={redirectClubgg}>Download ClubGG</button>                        
                    </div>
                </div>
                <div className='activate-membership-text-holder'>
                    <div>
                        <h1 className='step'>STEP 2</h1>                        
                    </div>
                    <div>
                        <h2>Verify Ownership</h2>
                        <button className='landing-container-center-right-discord-btn'><span className='discord-button'>Connect Wallet</span></button>                        
                        <div className='membershipNumber'>
                            {membershipNumber}
                        </div>
                    </div>
                </div>
                <div className='activate-membership-text-holder'>
                    <div>
                        <h1 className='step'>STEP 3</h1>                        
                    </div>
                    <div>
                        <h2>Submit User Details</h2>
                                        <div className='usernameButtons'>
                                            <input 
                                                className='usernameInput' 
                                                placeholder='ClubGG Username'
                                                onChange={event => SetDiscordId(event.target.value)}/>
                                            <input 
                                                className='usernameInput' 
                                                placeholder='ClubGG ID'
                                                onChange={event => SetClubGGID(event.target.value)}/>
                                            <input 
                                                className='usernameInput' 
                                                placeholder='Discord Username'
                                                onChange={event => SetClubGGUsername(event.target.value)}/>
                                        </div>
                        <button className='landing-container-center-right-signup-btn' onClick={setDetails}>Activate Membership</button>                        
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div className='grey-bk-section'>
            <div className='timetable-section'>
                <div className='timetable-container'>
                    <h1>TOURNAMENT SCHEDULE</h1>

                    <div className='timeTable'>
                        {/* <TablePage/> */}
                        <img src={Table}/>
                    </div>
                    <p>All prizes are the estimated retail value and claimed in USDC</p>
                </div>
            </div>
        </div>
                 {/* </AnimatedPage> */}  
        <div className="background-home-section-banner">
        <div className='footer-section'>
            <Footer/> 
        </div>
        </div>



            {/*<div className='mint-background'>
                 <AnimatedPage>
                <div className='image-header'>
                    <img src={pokerTable}/>
                </div>
                <div className='mint-background-inner'>
                    <div className='page-contents'>
                        <Snackbar
                            ref={snackbarRef}
                            message={snackBarMessage}
                            type={snackBarType}
                        />
                        <div className='title'> Poker
                        </div>
                        <div className='page-sections'>
                            <div className='left'>
                                <div data-aos="fade-right" className='assign-memebrship-text'>
                                    <h1>Activate VIP Season Pass</h1>
                                    <p>
                                        Here you can activate your <em>VIP Season Pass NFT</em> and upload your user information. You cannot participate
                                        in tournaments or collect winnings until this step is complete. 
                                    </p>
                                    <p>
                                        Please take care entering your details, ensuring they are 100% accurate. User info can be updated at anytime, 
                                        however each time will require a small gas fee.  
                                    </p>
                                </div>
                                    <div data-aos="fade-right" className='mebershipSearch'>
                                    <Button className='membershipButton' variant='red' onClick={getMemebrshipPass}>
                                        <Spinner
                                        as="span"
                                        variant="white"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation={loading}
                                        />
                                        <span className='buttonText'>{loadingText}</span>
                                    </Button>
                                        <div className='membershipNumber'>
                                            {membershipNumber}
                                        </div>
                                    </div>
                                    <div className='uploadMembership'>
                                        <Button className='membershipButton' variant="red" onClick={setDetails}>
                                            <Spinner
                                            as="span"
                                            variant="white"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            animation={loading2}
                                            />
                                            <span className='buttonText'>{loadingText2}</span>
                                        </Button>
                                        <div className='usernameButtons'>
                                            <input 
                                                className='usernameInput' 
                                                placeholder='ClubGG Username'
                                                onChange={event => SetDiscordId(event.target.value)}/>
                                            <input 
                                                className='usernameInput' 
                                                placeholder='ClubGG ID'
                                                onChange={event => SetClubGGID(event.target.value)}/>
                                            <input 
                                                className='usernameInput' 
                                                placeholder='Discord ID'
                                                onChange={event => SetClubGGUsername(event.target.value)}/>
                                        </div>
                                    </div>
                                </div>
                            <div className='right'>
                                <div data-aos="fade-left" className='table-container'>
                                    <h1>Tournament Timetable</h1>
                                    <div className='timeTable'>
                                        <TablePage/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                 </AnimatedPage>
            </div>
            <Footer/> */}
        </div>
    
    )
}

export default Poker;