import React, { useState, useEffect, useRef } from 'react'; 
// import MintButton from "../Minting/MintButton"
// import Minter from "../Minting/Minter";
import Navbar from "./../Navbar/Navbar"
import "./home.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram, faTwitterSquare } from "@fortawesome/free-brands-svg-icons"
import CasinoMov from "./../../videos/casino-loop.mp4"
import logo from './../../images/MobileLanding.png'
// import logo from "./../../images/LogoMainNoBk2.png"
import ReactiveButton from 'reactive-button';
import MintButton from '../../minter/MintButton'
import Footer from "../Footer/Footer";
import Video from "./../../utils/Videos/Video"
import AOS from 'aos';
import 'aos/dist/aos.css';

import mainBackground from "./../../imagesNew/Background.png"
import landingLogo from "./../../imagesNew/LandingLogo2.png"
import S1Logo from "./../../imagesNew/S1 Icon.png"
import icon1 from "./../../imagesNew/Advantage Icon 1.png"
import icon2 from "./../../imagesNew/Advantage Icon 2.png"
import icon3 from "./../../imagesNew/Advantage Icon 3.png"
import icon4 from "./../../imagesNew/Advantage Icon 4.png"
import icon5 from "./../../imagesNew/Advantage Icon 5.png"
import icon6 from "./../../imagesNew/Advantage Icon 6.png"
import Suits4 from "./../../imagesNew/4 Suit Icons.png"

AOS.init();


function Home() {
  const [ageVerify, SetAgeVerify] = useState(false)
  const [privacyVerify, SetPrivacyVerify] = useState(false)
  const [mintDisabled, SetMintDisabled] = useState(true)
  const [discordButton, SetdiscordButton] = useState("Join the Discord")

  function callMint(){
      console.log("test")
  }

    useEffect(() => {
        checkVerifyCheckboxes()
    });

    async function checkVerifyCheckboxes(){
        if(ageVerify == true && privacyVerify == true){
            SetMintDisabled(false)
        }else{
            SetMintDisabled(true)
        }
    }

    const handlePrivacyVerifyChange = event => {
        if (event.target.checked) {
            SetAgeVerify(true)
        } else {
            SetAgeVerify(false)
        }
    };

    const handleAgeVerifyChange = event => {
        if (event.target.checked) {
            SetPrivacyVerify(true)
        } else {
            SetPrivacyVerify(false)
        }
    };

    const handleClickScroll = () => {
        const element = document.getElementById('becomeAMember');
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };


  function openDiscord(){
    window.open(
        ' https://discord.com/invite/tempuspoker',
        '_blank'
      );
    }

  return (
    <div className="home-container">
        <div className="background-home">
            <Navbar />
            <div className='landing-container-center'>
                <div className='landing-container-center-container'>   
                    <div className='landing-container-center-left'>
                        <img src={landingLogo}></img>
                    </div>
                    <div className='landing-container-center-right'>
                        <h1>THE WORLD'S FIRST MEMBERS-ONLY</h1>
                        <h1>FREEROLL POKER CLUB</h1>
                        <button onClick={handleClickScroll} className='landing-container-center-right-signup-btn'>Become a Member</button>
                        <button onClick={openDiscord}  className='landing-container-center-right-discord-btn'><span className='discord-button'>Join the Discord</span></button>
                    </div>
                </div>
            </div>
            <div className='landing-container-center-mobile'>
                <div className='landing-container-center-container-mobile'>   
                    <div className='landing-container-center-left'>
                        <img src={landingLogo}></img>
                    </div>
                    <div className='landing-container-center-right'>
                        <h1>THE WORLD'S FIRST MEMBERS-ONLY</h1>
                        <h1>FREEROLL POKER CLUB</h1>
                        <button onClick={handleClickScroll} className='landing-container-center-right-signup-btn'>Become a Member</button>
                        <button onClick={openDiscord}  className='landing-container-center-right-discord-btn'><span className='discord-button'>Join the Discord</span></button>
                    </div>
                </div>
            </div>
            <div className='down-arrow2'></div>
        </div>
        <div className='grey-bk-section'>
            <div className='who-are-we-section'>
                <div className='who-are-we-section-img'>
                    <img className='who-are-we-img' src={Suits4}></img>
                </div>
                <div className='who-are-we-section-text'>
                    <h1>WHO ARE WE?</h1>
                    <p>We are the first of our kind, a freeroll poker club catering to the recreational player, 
                        the average Joe who never cracks those winning placings. We've created an exclusive club experience where you'll get more of what you've always wanted. 
                        Superior odds, bigger prizes, fewer restrictions, faster withdrawals, privacy, and transparency — as a member you will always play for free.</p>
                </div>
            </div>
        </div>
        <div className="background-home-how-it-works">
            <div className='how-it-works-section2'>
                <div className='title-how-it-works'>
                    <h1>HOW IT WORKS</h1>
                </div>
                {/* <div className='how-it-works-section-text'>
                    <div>            
                        <h1>HOW IT WORKS</h1>
                        <p><em>STEP 1</em>Purchase a membership with card or cryptocurrency or join the club</p>
                        <p><em>STEP 2</em>Activate your membership by submitting your username and digital wallet</p>
                        <p><em>STEP 3</em>Gain unlimited access to private freeroll tournaments</p>
                    </div>
                </div> */}
                <div className='how-it-works-section-text-holder'>
                    <div>
                        <h1 style={{fontFamily: "RobotItalic"}}>STEP 1</h1>                        
                    </div>
                    <div>
                        <p>Purchase a membership with card or cryptocurrency to join the club</p>
                    </div>
                </div>
                <div className='how-it-works-section-text-holder'>
                    <div>
                        <h1 style={{fontFamily: "RobotItalic"}}>STEP 2</h1>                        
                    </div>
                    <div>
                        <p>Activate your membership by submitting your username and digital wallet</p>
                    </div>
                </div>
                <div className='how-it-works-section-text-holder'>
                    <div>
                        <h1 style={{fontFamily: "RobotItalic"}}>STEP 3</h1>                        
                    </div>
                    <div>
                       <p>Gain unlimited access to private freeroll tournaments</p>
                    </div>
                </div>
            </div>
        </div>
        <div className='grey-bk-section'>
            <div className='who-are-we-section'>
                <div className='who-are-we-section-img-mobile'>
                    <img className='S1Icon2' src={S1Logo}></img>
                </div>
                <div className='who-are-we-section-text'>
                    <h1>A LOOK AT OUR SEASON ONE MEMBERSHIP</h1>
                    <h2>What You Get</h2>
                    <p>• Unlimited access to 32 freeroll (free-to-enter) tournaments</p>
                    <p>• Tournaments hosted every 2-3 days</p>
                    <p>• Average tournament prize pools worth $20,000</p>
                    <br/>
                    <p> Tempus Poker Club hosts 4 seasons per year - Memberships are a one-time purchase and valid for individual seasons (3 months). Club members have the freedom to resell their membership at any time.</p>
                </div>
                <div className='who-are-we-section-img'>
                    <img className='S1Icon' src={S1Logo}></img>
                </div>
            </div>
        </div>
        <div className="background-home">
            <div className='advantage-section'>
                <h1>OUR ADVANTAGE</h1>
                <div className='advantage-section-container'>     
                    <div className='advantage-top-row'>
                        <div className='advantage-card'>
                            <div className='advantage-card-img'><img src={icon1}/></div>
                            <h2 style={{maxWidth:"200px"}}>Fast Withdrawals</h2>
                            <div className='advantage-card-text'>Unlike traditional poker platforms we don't make you jump through hoops to claim your prize. Once you start your withdrawal it's done in seconds.</div>
                        </div>
                        <div className='advantage-card'>
                            <div className='advantage-card-img'><img src={icon2}/></div>
                            <h2 style={{maxWidth:"250px"}}>No Fees, Rake, or Buy-ins</h2>
                            <div className='advantage-card-text'>We don't take rake, charge club fees, or buy-ins. You always play for free and keep what you win, the way it should be.</div>
                        </div>
                        <div className='advantage-card'>
                            <div className='advantage-card-img'><img src={icon3}/></div>
                            <h2 style={{maxWidth:"250px"}}>Better Odds for Average Players</h2>
                            <div className='advantage-card-text'>We cater to the average player, no more sitting in a tournament for 10 hours to win $5. You can win up to $100 every week from as low as a 250th placing.</div>
                        </div>
                    </div>
                    <div className='advantage-bottom-row'>
                        <div className='advantage-card'>
                            <div className='advantage-card-img'><img src={icon4}/></div>
                            <h2 style={{maxWidth:"250px"}}>Smaller & Faster Tournaments</h2>
                            <div className='advantage-card-text'>Freezeout style tournaments combined with a modest club size means you will always enjoy smaller, faster tournaments with better odds.</div>
                        </div>
                        <div className='advantage-card'>
                            <div className='advantage-card-img'><img src={icon5}/></div>
                            <h2 style={{maxWidth:"200px"}}>Community & Camaraderie</h2>
                            <div className='advantage-card-text'>At Tempus you aren't just a player at the table, you are member of an exclusive poker club where you can enjoy the camaraderie of your fellow members.</div>
                        </div>
                        <div className='advantage-card'>
                            <div className='advantage-card-img'><img src={icon6}/></div>
                            <h2 style={{maxWidth:"200px"}}>Anonymity & Transparency</h2>
                            <div className='advantage-card-text'>Keep your cards hidden, but we'll show you ours. Members maintain control over their privacy while knowing that we provide fully transparent, auditable records.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='grey-bk-section'>
            <div className='join-club-section' id='becomeAMember'>
                <h4>READY TO JOIN THE CLUB?</h4>
                <div className='join-club-section-container'>
                    <div className='club-packages-containers'>
                        <div className='package-card'>
                            <div className='price-section'>
                                <h1>$159.99</h1>
                                <h3>$53.33/mo</h3>
                            </div>
                            <div className='length-section'>
                                <h1>3 Months</h1>
                            </div>
                            <div className='bottom-section'>
                                <h1>1 Season</h1>
                                <button className='join-button'>Coming Soon</button>
                                <p>Up to 4,000 Available</p>
                            </div>
                        </div>
                        <div className='package-card-middle'>
                            <div className='price-section'>
                                <h1>$239.99</h1>
                                <h3 style={{colour:"red"}}>$39.99/mo</h3>
                            </div>
                            <div className='length-section'>
                                <h1>6 Months</h1>
                            </div>
                            <div className='bottom-section'>
                                <h1>2 Seasons</h1>
                                <button className='join-button'>Coming Soon</button>
                                <p>Up to 1,000 Available</p>
                            </div>
                        </div>
                        <div className='package-card'>
                            <div className='price-section'>
                                <h1>$429.99</h1>
                                <h3 style={{colour:"#959595"}}>$35.83/mo</h3>
                            </div>
                            <div className='length-section'>
                                <h1>12 Months</h1>
                            </div>
                            <div className='bottom-section'>
                                <h1>4 Seasons</h1>
                                <button className='join-button'>Coming Soon</button>
                                <p>Up to 555 Available</p>
                            </div>
                        </div>
                    </div>
                    <div className='legal-section'>
                        <div className='verifiyContainer'>
                            <input name='verifyCheckboxes' onChange={handlePrivacyVerifyChange} type="checkbox" className='verifyCheckbox'></input>
                                <p>I confirm that I have read and agree to the <a href="https://tempuspoker.com/TermsAndConditions" target="_blank">Terms & Conditions </a>
                                & <a href="https://tempuspoker.com/Privacy" target="_blank">Privacy Policy</a>
                            </p>
                        </div>
                        <div className='verifiyContainer2'>
                            <input name='verifyCheckboxes' onChange={handleAgeVerifyChange} type="checkbox" className='verifyCheckbox'></input>
                                <p>I confirm that I am 18 years of age or older
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="background-home-section-banner">
            <div className='footer-section'>
                <Footer/> 
            </div>
        </div>
      </div>
  );
}

export default Home;