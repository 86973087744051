import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram, faTwitterSquare, faDiscord, faTwitch} from "@fortawesome/free-brands-svg-icons"
import logo from "./../../imagesNew/LandingLogo2.png"

import './Footer.css'
import CrossmintLogo from "./../../imagesNew/Crossmint Logo.png"
//import BlueBasinLogo from "../../images/BlueBasinLogo.png"

const Footer = () => {

    function redirectToPartner(){
        window.open(
            'https://www.bluebasincanna.com/',
            '_blank'
          );
    }

    function redirectToPartner2(){
        window.open(
            'https://www.crossmint.io/',
            '_blank'
          );
    }

    const BlueBasinLogo = "https://venftian.s3.eu-west-2.amazonaws.com/BlueBasinLogo.png"
    //const CrossmintLogo = "https://venftian.s3.eu-west-2.amazonaws.com/CrossmintLogo.png"

    return(
        <div className="footer-bk">
            <div className="footer-overlay">
                {/* <div className="disclaimer">
                    *All prizes shown are the estimated retail value and paid in cryptocurrency*
                </div> */}
                <div className="partnerBanner">
                    <div className="partnersTitle">
                        <h1>OUR PARTNERS</h1>
                    </div>
                    <div className="partnerLogos">
                        <div className="parnerLeft"><img src={BlueBasinLogo} onClick={redirectToPartner}/></div>
                        <div className="parnerRight"><img src={CrossmintLogo} onClick={redirectToPartner2}/></div>
                    </div>
                </div>
                <div className="background-footer">
                        <div className="footer-bottom">
                            <div className="col1">
                                <div className="footer-info">
                                    <div className="footer-logo-container">
                                        <a className="footer-logo" href="#">
                                            <img src={logo}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col2">
                                <p><a href="https://tempuspoker.com/"> Home</a> | <a href="https://tempuspoker.com/About"> About</a> | <a href="https://tempuspoker.com/Poker">Poker </a> | <a href="https://tempuspoker.com/Prizes"> Prizes</a>  |
                                <a href="https://tempuspoker.com/FAQ"> FAQ</a>
                                </p>
                                <p><a href="https://tempuspoker.com/TermsAndConditions" target="_blank">Terms & Conditions </a> |<a href="https://tempuspoker.com/Privacy" target="_blank"> Privacy Policy</a></p>
                                <p className="copyright">©2023 Tempus Poker Club | All Rights Reserved</p>
                            </div>
                            <div className="col3">
                                <div className="socials-col1">
                                    Find us on these socials:
                                </div>
                                <div className="socials-col2">
                                    <div className="link"><a href="https://twitter.com/tempuspoker" target="_blank"
                                            name="twitter" className="twitter row">
                                            <FontAwesomeIcon icon={faTwitterSquare} />
                                        </a>
                                    </div>
                                    <div className="link">
                                        {/* <a href="https://www.twitch.tv/tempusokerclub" target="_blank" name="discord" */}
                                        <a
                                            className="discord row"><FontAwesomeIcon icon={faTwitch} />
                                        </a>
                                    </div>
                                    <div className="link">
                                        <a href="https://discord.com/invite/tempuspoker" target="_blank" name="discord"
                                            className="discord row"><FontAwesomeIcon icon={faDiscord} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>                
            </div>

        </div>
    )
}

export default Footer;