import React, { useState, useEffect, useRef } from 'react'; 
// import MintButton from "../Minting/MintButton"
// import Minter from "../Minting/Minter";
import Navbar from "./../Navbar/Navbar"
import "./about.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram, faTwitterSquare } from "@fortawesome/free-brands-svg-icons"
import CasinoMov from "./../../videos/casino-loop.mp4"
import logo from './../../images/MobileLanding.png'
// import logo from "./../../images/LogoMainNoBk2.png"
import ReactiveButton from 'reactive-button';
import MintButton from '../../minter/MintButton'
import Footer from "../Footer/Footer";
import Video from "./../../utils/Videos/Video"
import AOS from 'aos';
import 'aos/dist/aos.css';

import cards from "./../../imagesNew/cards.png"
import spade from "./../../imagesNew/spade.png"
import Suits4 from "./../../imagesNew/4 Suit Icons.png"
import SolutionTable from "./../../imagesNew/solutionTable.png"
import ROADMAP from "./../../imagesNew/WebsiteRoadmap.png"
import team from "./../../imagesNew/team.png"
import scott from "./../../imagesNew/scott2.png"
import tom from "./../../imagesNew/tom2.png"
import ryan from "./../../imagesNew/ryan2.png"

AOS.init();


function About() {
  const [ageVerify, SetAgeVerify] = useState(false)
  const [privacyVerify, SetPrivacyVerify] = useState(false)
  const [mintDisabled, SetMintDisabled] = useState(true)

  function callMint(){
      console.log("test")
  }

    useEffect(() => {
        checkVerifyCheckboxes()
    });

    async function checkVerifyCheckboxes(){
        if(ageVerify == true && privacyVerify == true){
            SetMintDisabled(false)
        }else{
            SetMintDisabled(true)
        }
    }

    const handlePrivacyVerifyChange = event => {
        if (event.target.checked) {
            SetAgeVerify(true)
        } else {
            SetAgeVerify(false)
        }
    };

    const handleAgeVerifyChange = event => {
        if (event.target.checked) {
            SetPrivacyVerify(true)
        } else {
            SetPrivacyVerify(false)
        }
    };




  return (
    <div className="home-container">
        <div className="background-home-section-banner">
            <Navbar/>
            <div className='section-titles'>
                <div className='how-it-works-section-text'>
                    <div className='page-title'>            
                        <h5>ABOUT</h5>
                    </div>
                </div>
            </div>
            <div className='down-arrow'></div>
        </div>
        <div className='grey-bk-section'>
            <div className='who-are-we-section'>
                <div className='who-are-we-section-text'>
                    <h1 style={{textAlign : "center"}}>FIRST OF ITS KIND</h1>
                    <p style={{textAlign : "center"}}>Tempus Poker Club is the world's first freeroll poker club that caters to the recreational player, the average Joe who never cracks those top placings. 
                        Instead of traditional top-heavy payouts, we adopt a more balanced and inclusive prize structure. Poker should be fun, and winning is fun. 
                        Now winning has never been more within reach.</p>
                    <p style={{textAlign : "center"}}>
                        Through leveraging Blockchain technology, we are able to remove the middle man and give players more of what they want — 
                        a better tournament experience, better odds, and do it all for less.</p>
                </div>
            </div>
        </div>
        <div className="background-home-how-it-works">
            <div className='who-are-we-section' style={{paddingTop:"50px"}}>
                <div className='who-are-we-section-img'>
                    <img className='who-are-we-img' src={spade}></img>
                </div>
                <div className='who-are-we-section-text'>
                    <h1>THE PROBLEM</h1>
                    <p>The reason why traditional online poker needs an overhaul...</p>
                    <p>
                        High overheads, high production costs, high rakes, and even higher fees. Crowded tournaments, slow payouts, geo-restrictions, no transparency, 
                        and overly top-heavy prize pools that exclude the average player. For decades players have been bled dry through micro-transactions and fees.
                        </p>
                    <p>No longer will players have to pay the price.</p>
                </div>
            </div>
        </div>
        <div className='grey-bk-section'>
            <div className='who-are-we-section'>
                <div className='who-are-we-section-text'>
                    <h1 style={{textAlign : "center"}}>OUR SOLUTION</h1>
                    <p  style={{textAlign : "center"}}>To create a private club experience and transform 
                    traditional memberships through the use of Blockchain technology. 
                    In doing so we can provide players with a seamless, cost-effective experience 
                    while offering superior odds, bigger prizes, fewer restrictions, faster withdrawals, user privacy, 
                    and house transparency, all without fees.</p>

                </div>
            </div>
        </div>
        <div className="background-home">
            <div className='solution-section'>
                <div className='solution-container'>
                    <h1>TEMPUS ADVANTAGE</h1>

                    <div className='solution-table'>
                        {/* <TablePage/> */}
                        <img src={SolutionTable}/>
                    </div>
                </div>
            </div>
        </div>
        <div className='grey-bk-section'>
            <div className='who-are-we-section'>
                <div className='who-are-we-section-img-mobile'>
                    <img className='S1Icon2' src={cards}></img>
                </div>
                <div className='who-are-we-section-text'>
                    <h1>SEASONS</h1>
                    <h2 style={{color : "#cb0600"}}>PRE-SEASON (Free Trial)</h2>
                    <p style={{margin:0 }}><em style={{color : "red"}}>•</em> Unlimited access to 4 freeroll (free-to-enter) tournaments</p>
                    <p style={{margin:0 }}><em style={{color : "red"}}>•</em> Tournaments hosted once per week</p>
                    <p style={{margin:0 }}><em style={{color : "red"}}>•</em> Average tournament prize pool worth $2,500</p>
                    <p style={{margin:0 }}><em style={{color : "red"}}>•</em> One-month duration</p>
                    <p style={{margin:0 }}><em style={{color : "red"}}>•</em> Allows players to experience Tempus Poker Club with zero risk</p>
                    <br/><br/>
                    <h2 style={{color : "#cb0600"}}>SEASON ONE (Paid Membership)</h2>
                    <p style={{margin:0 }}><em style={{color : "red"}}>•</em> Unlimited access to 32 freeroll (free-to-enter) tournaments</p>
                    <p style={{margin:0 }}><em style={{color : "red"}}>•</em> Tournaments hosted every 2-3 days</p>
                    <p style={{margin:0 }}><em style={{color : "red"}}>•</em> Average tournament prize pool worth $20,000</p>
                    <p style={{margin:0 }}><em style={{color : "red"}}>•</em> Memberships are valid for 3 months (1 season)</p>
                    <p style={{margin:0 }}><em style={{color : "red"}}>•</em> Memberships are a one-time purchase</p>
                    <p><em style={{color : "red"}}>•</em> Members can resell their membership at any time</p>
                    <br/>
                    <p> Our tournament calendar year consists of 4 seasons — memberships are valid for individual seasons (3 months).</p>
                </div>
                <div className='who-are-we-section-img'>
                    <img className='S1Icon' src={cards}></img>
                </div>
            </div>
        </div>
        <div className="background-home">
            <div className='solution-section'>
                <div className='solution-container'>
                    <h1>THE ROAD AHEAD</h1>

                    <div className='solution-table'>
                        {/* <TablePage/> */}
                        <img style={{backgroundColor:"rgba(0, 0, 0, 0)" }} src={ROADMAP}/>
                    </div>
                </div>
            </div>
        </div>
        <div className='grey-bk-section'>
            <div className='team-section'>
                <div className='team-container'>
                    <h1>THE TEAM</h1>

                    <div className='team-table'>
                        {/* <TablePage/> */}
                        <img src={team}/>
                    </div>
                </div>
                <div className='team-container-mobile'>
                    <h1>THE TEAM</h1>
                    <div className='team-table'>
                        <img src={scott}/>
                    </div>
                    <div className='team-table'>
                        <img src={ryan}/>
                    </div>
                    <div className='team-table'>
                        <img src={tom}/>
                    </div>
                </div>
            </div>
        </div>
        <div className="background-home-section-banner">
            <div className='footer-section'>
                <Footer/> 
            </div>
        </div>
      </div>
  );
}

export default About;