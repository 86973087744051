import './prizes.css'
import {ethers} from 'ethers';
import AnimatedPage from '../../utils/AnimatedPage';
// import pokerTable from "../../images/pokerTable2.jpeg"
import Snackbar from "./../../utils/snackbar";
import React, { useState, useEffect, useRef } from 'react'; 
import { Button, Spinner } from 'react-bootstrap'
import ReactiveButton from 'reactive-button';
// import Payout from '../../images/prizePool3.png'
// import PayoutDesktop from '../../images/prizePool3.png'
import Navbar from "./../Navbar/Navbar"

import Footer from "../Footer/Footer"
// import banner from "../../images/Desktop_Website_Banner.png"
// import banner2 from "../../images/Mobile_Website_Banner.png"
import contractJson from "../../../template.json";
import AOS from 'aos';
import 'aos/dist/aos.css';

import valutIcon from "./../../imagesNew/Vault Icon.png"


AOS.init();

const contractAddress = "";
const SnackbarType = {
    success: "success",
    fail: "fail",
};
const banner = "https://venftian.s3.eu-west-2.amazonaws.com/Desktop_Website_Banner.png"
const banner2 = "https://venftian.s3.eu-west-2.amazonaws.com/Mobile_Website_Banner.png"
const Payout = 'https://venftian.s3.eu-west-2.amazonaws.com/Prize_Page.jpg'
const PayoutDesktop = 'https://venftian.s3.eu-west-2.amazonaws.com/Prize_Page.jpg'

function Prizes(){
    const [snackBarType, SetSnackBarType] = useState(SnackbarType.fail)
    const [snackBarMessage, SetSnackBarMessage] = useState("")
    const [loading, setLoading] = useState("none");
    const [loadingText, setLoadingText] = useState("Check Vault");
    const [loadingPrize, setLoadingPrize] = useState("none");
    const [loadingTextPrize, setLoadingTextPrize] = useState("Claim Prize");
    const [prizeWinnings, setPrizeWinnings] = useState(0)
    const [connectedContract, setConnectedContract] = useState(null);
    const pokerTable = "https://venftian.s3.eu-west-2.amazonaws.com/bannerNoPasses.png"
    const snackbarRef = useRef(null);

    function buttonLoad(){
        if(loading == "none"){
            setLoading("border")
            setLoadingText(" Loading")
        }
    }

    async function loadPrizes(){
        setLoading("border")
        setLoadingText(" Loading")
        setTimeout(() => {
            SetSnackBarMessage("No Membership Found")
            SetSnackBarType(SnackbarType.fail)
            snackbarRef.current.show() 
            setLoading("none")
            setLoadingText("Check Vault")
         }, 2900);    
    }

    async function claimPrizes(){
        setLoadingPrize("border")
        setLoadingTextPrize(" Loading")
        setTimeout(() => {
            SetSnackBarMessage("No Membership Found")
            SetSnackBarType(SnackbarType.fail)
            snackbarRef.current.show() 
            setLoading("none")
            setLoadingText("Claim Prize")
         }, 2900);    
    }

    const getConnectedContract = async () => {
        const {ethereum} = window;
        if(!ethereum) return;
    
        const provider = new ethers.providers.Web3Provider(
          ethereum
        );
    
        const signer = provider.getSigner();
    
        const connectedContract =
          new ethers.Contract(
            contractAddress,
            contractJson.abi,
            signer
          )
        setConnectedContract(connectedContract)
    }

    async function claimPrizes(){
        // var passNumber = "Not Found";
        // try{
        //   if(!connectedContract) return;
        //   passNumber = await connectedContract.returnCounter()
        //   setLoading("None")
        //   setLoadingText("Loaded")
        // }catch(err){
            SetSnackBarMessage("No Prizes To Claim")
            SetSnackBarType(SnackbarType.fail)
            snackbarRef.current.show()     
            //console.log(err);
        //}
    }

    return(
    <div className='container2'>
        {/* <AnimatedPage> */} 
                <div className="background-home-section-banner">
                    <Navbar/>
                    <div className='section-titles'>
                        <div className='how-it-works-section-text'>
                            <div className='page-title'>            
                                <h5>PRIZES</h5>
                            </div>
                        </div>
                    </div>
                    <div className='down-arrow'></div>
                </div>
                <Snackbar
                    ref={snackbarRef}
                    message={snackBarMessage}
                    type={snackBarType}
                />
                <div className='grey-bk-section'>
                    <div className='claim-prize-section'>                
                        <div className='who-are-we-section'>
                            <div className='who-are-we-section-img-mobile'>
                                <img className='vaultIcon' src={valutIcon}></img>
                            </div>
                            <div className='who-are-we-section-text'>
                                <h1>HOW TO CLAIM</h1>
                                <p>After a succcessful audit of each tournament, prizes will be made available to claim from the club vault.</p>
                                <p>Simply connect your digital wallet and click "Check Vault" below to see your available balance. From there you can select "Claim Prize" to have your winnings sent directly to your digital wallet in seconds.</p>
                            </div>
                            <div className='who-are-we-section-img'>
                                <img className='vaultIcon' src={valutIcon}></img>
                            </div>
                        </div>
                        <div className='claim-prize-buttons'>
                            <button className='landing-container-center-right-discord-btn'onClick={loadPrizes}>
                                <Spinner
                                as="span"
                                variant="black"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                animation={loading}
                                />
                                <span className='checkVaultText'>{loadingText}</span>
                            </button>
                                <div className='prizeWinnings'>
                                    {prizeWinnings}
                                </div>
                        
                        </div>
                        <div className='claim-prize-buttons'>  
                            <button className='landing-container-center-right-signup-btn' variant='red' onClick={claimPrizes}>
                                <Spinner
                                as="span"
                                variant="white"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                animation={loadingPrize}
                                />
                                <span className='buttonText'>Claim Prize</span>
                            </button>                        
                        </div>
                        <div className='claim-prize-text'>
                            <p>Withdrawals require a very small network fee estimated at 50¢ to $1.50 worth of Ethereum. This fee does not go to the club, but is necesarry to provide you with nearly instant withdrawals. All prizes listed are the estimated retail value and claimed in USDC.</p>
                        </div>
                    </div>
                </div>
                 {/* </AnimatedPage> */}  
            <div className="background-home-section-banner">
            <div className='footer-section'>
                <Footer/> 
            </div>
            </div>



    </div>
    )
}

export default Prizes;