import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram, faTwitterSquare, faDiscord, faTwitch} from "@fortawesome/free-brands-svg-icons"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

const AccordionItem = ({
  showDescription,
  ariaExpanded,
  fontWeightBold,
  item,
  index,
  onClick,
}) => (
  <div className="faq__question" key={item.question}>
    <dt>
      <button
        aria-expanded={ariaExpanded}
        aria-controls={`faq${index + 1}_desc`}
        data-qa="faq__question-button"
        className={`faq__question-button ${fontWeightBold}`}
        onClick={onClick}
      >
        {item.question}      
          <FontAwesomeIcon icon={faAngleDown} />
        </button>
    </dt>
    <dd>
      <p
        id={`faq${index + 1}_desc`}
        data-qa="faq__desc"
        className={`faq__desc ${showDescription}`}
      >
        {item.answer}
        {(item.answer2 !== undefined) ? <br/> : null}
        {item.answer2}
        {(item.answer3 !== undefined) ? <br/> : null}
        {item.answer3}
        {(item.answer4 !== undefined) ? <br/> : null}
        {item.answer4}
        {(item.answer5 !== undefined) ? <br/> : null}
        {item.answer5}
        {(item.answer6 !== undefined) ? <br/> : null}
        {item.answer6}
        {(item.answer7 !== undefined) ? <br/> : null}
        {item.answer7}
        {(item.answer8 !== undefined) ? <br/> : null}
        {item.answer8}
        {(item.answer9 !== undefined) ? <br/> : null}
        {item.answer9}
        {(item.answer10 !== undefined) ? <br/> : null}
        {item.answer10}
        {(item.answer11 !== undefined) ? <br/> : null}
        {item.answer11}
        {(item.answer12 !== undefined) ? <br/> : null}
        {item.answer12}
        {(item.answer13 !== undefined) ? <br/> : null}
        {item.answer13}
        {(item.answer14 !== undefined) ? <br/> : null}
        {item.answer14}
        {(item.answer15 !== undefined) ? <br/> : null}
        {item.answer15}
        {(item.answer16 !== undefined) ? <br/> : null}
        {item.answer16}
        <br></br><br></br><br></br>
      </p>
    </dd>
  </div>
);

export default AccordionItem;