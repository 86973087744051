import './FAQ.css'
import {ethers} from 'ethers';
import AnimatedPage from '../../utils/AnimatedPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect, useRef } from 'react'; 
import { MDBBtn } from "mdbreact";
import Snackbar from "./../../utils/snackbar";
import Footer from "../Footer/Footer"
import AOS from 'aos';
import Accordion from "./Accordian.js";
import 'aos/dist/aos.css';
import Navbar from "./../Navbar/Navbar"


AOS.init();

const SnackbarType = {
  success: "success",
  fail: "fail",
};

const MembershipQuestionsAnswers = [
    {
      question: "What is Tempus Poker Club?",
      answer:
        "Tempus is the first of its kind, a freeroll poker club where membership is purchasable as a digital asset. Members have unlimited access to $20,000 free-to-enter tournaments hosted every other day. ",
      answer2:
        "We cater to the recreational player, the average joe who never cracks those top placings. Instead of traditional top-heavy payouts, we adopt more balanced and accessible prize structures. Winning has never been more within reach."
    },
    {
      question: `Are all tournaments/events "free-to-enter" for club members?`,
      answer:
        "Yes, we follow the traditional format of a golf/country club where all events are strictly free-to-enter for club members. Members will never be required to make a purchase as long as they hold membership."
    },
    {
      question: "What do I get with my membership?",
      answer: `• Access to the Tempus Poker Club community`,
      answer2: `• Access to 32 private freeroll (free-to-enter) poker tournaments every 3 months`,
      answer3: `• Freeroll tournaments hosted every 2-3 days for club members`,
      answer4: `• Average tournament prize pools worth $20,000`,
      answer5: `• ZERO rake, hidden fees, or buy-ins while you are a member`,
      answer6: `• Significantly better odds than competitors`,
      answer7: `• Significantly faster prize reclamation than competitors`,
      answer8: `• Significantly smaller tournament sizes than competitors`,
      answer9: `• Significantly faster tournament times with no re-buys possible`,
      answer10: `• User anonymity and full transparency of the house`,
      answer11: `• The freedom to resell your membership if you can no longer participate`,
    },
    {
      question: "How long is my membership valid for?",
      answer: `Your membership will remain valid for the duration that it was purchased for. This is 3-12 months depending on what you purchased.`
    },
    {
      question: `Can a friend or family member "borrow" my membership?`,
      answer: `A membership cannot be "borrowed", you would be required to transfer ownership of your membership.`,
    },
    {
        question: "Can I sell my membership?",
        answer: "Yes, you can list your membership at any time on the secondary market for someone else to enjoy.",
    },
    {
        question: "Will non-members have access to club hosted tournaments?",
        answer: `Generally no, but we do host community events where we allow select partner groups to participate.`
    },
    {
        question: "Can I purchase a membership with my credit card?",
        answer: `Yes, we accept most credit cards along with Ethereum and Solana cryptocurrencies.`,
    },
    {
        question: "Are there benefits to purchasing multiple memberships?",
        answer: `Yes, it is common for poker players play on multiple devices and this would require multiple memberships. There are also many exclusive draws for holders of multiple memberships.`,
    },
    {
        question: "Is there an age requirement to be a member?",
        answer: `Yes, all members must be at least 18 years of age.`
    },
    {
        question: "How do I register/activate my membership after purchase?",
        answer: `Once purchased, a membership can be registered on our website in seconds at www.TempusPoker.com/Poker. You will be required to submit your digital wallet (See - https://ethereum.org/en/wallets/). A digital wallet is a mobile/browser app that allows you to store digital assets and interact with blockchain technology. If you do not have a digital wallet one can be created anonymously in seconds, our preferred wallet of choice is Metamask. (https://metamask.io/)`,
    },
    {
        question: "Where does my membership get sent after purchase?",
        answer: `Depending on your method of purchase (Credit card/crypto) your membership will be sent to a digital wallet created automatically, or sent to a digital wallet of your choice.`
    }
  ];

  const tournamentQuestionsAnswers = [
    {
      question: "Do I need to be good at poker to win anything?",
      answer:
        "No! We cater to the recreational player, the average joe who never cracks those top placings. Instead of traditional top-heavy payouts, we adopt more balanced and accessible prize structures. Winning has never been more within reach. Even our lowest paid placings of 250th place reward $50-$100."
    },
    {
      question: "How large are prize pools?",
      answer:
        "In our first season the average tournament prize pool will be worth an estimated $20,000."
    },
    {
      question: `What are "Seasons"?`,
      answer: `At Tempus Poker Club we split the calendar year into 4 seasons. Each season lasts for 3 months and memberships are purchased for individual seasons.`,

    },
    {
      question: "How many tournaments are hosted throughout the season?",
      answer: `Our first season will consist of 32 private free-to-enter tournaments.`
    },
    {
      question: "How often are tournaments?",
      answer: `Tournaments are hosted every 2-3 days for members of the club.`,
    },
    {
      question: "Are tournaments free-to-enter?",
      answer: `Yes, all tournaments are strictly free-to-enter and do not require an entry fee.`,
    },
    {
      question: "Do you offer a free trial or is that your Pre-Season?",
      answer: `We offer a free one month Pre-Season membership prior to the launch of our Season 1. The one month Pre-Season operates as a free trial and allows players to experience Tempus Poker Club with zero risk and access to $10,000 worth of tournaments.`,
    },
    {
      question: " Do you allow buy-backs or re-entry?",
      answer: `No, all of our tournaments are "freezeout", this is the same format as the World Series of Poker main event. Once you are out of chips you are eliminated from the tournament.`,
    },
    {
      question: "What do tournament pay-out structures look like?",
      answer: `Prize structures are subject to change, but the average Season 1 tournament will follow the below structure:`,
      answer2: `     `,
      answer3: `• Total Pool Worth $20,000`,
      answer4: `• Top 250 Placings Paid`,
      answer5: `• 1st - $2,000`,
      answer6: `• 2nd - $1,400`,
      answer7: `• 3rd - $1,000`,
      answer8: `• 4th - $850`,
      answer9: `• 5th - $700`,
      answer10: `• 6th-250th - Gradually Lowers`,
      answer11: `        `,
      answer12: `Our tournaments follow a modified WSOP calculation to create a less top heavy, more balanced pay-out structure catering to the average recreational player.`,
      answer13: `            `,
      answer14: `A complete tournament schedule can be found in the "Poker" page`
    },
    {
      question: "Do you take a rake or charge any fees?",
      answer: `No, unlike our competitors we do not take rake, charge admin fees, deposit fees, withdrawal fees or any other fees. Our members will always play for free.`,
    },
    {
      question: "How are tournaments funded?",
      answer: `Our tournaments are funded through membership sales.`,
    },
    {
      question: "Are all tournaments Texas Hold 'em?",
      answer: `Yes, our first season will consist of only Texas Hold 'em tournaments.`,
    },
    {
      question: "Do you allow late entry for tournaments?",
      answer: `Yes, tournaments can be joined up to 30 minutes after the scheduled start time.`,
    },
    {
      question: "If I win how do I claim my prize?",
      answer: `You will be able to withdraw your winnings on our website in a matter of seconds, located in the "Prize" page`,
      answer2: `     `,
      answer3: `• Select the "Connect Wallet" button and follow the prompts to connect the digital wallet associated with your membership (See - https://ethereum.org/en/wallets/).`,
      answer4: `• Select the "withdraw winnings" button to finalize your withdrawal.`,
      answer5: `       `,
      answer6: `Note: Prize reclamation requires a very small network fee estimated at 50¢ to $1.50. This fee is paid with Ethereum and does not go to us, but is necessary as it allows us to utilize the benefits of blockchain technology and offer you more. All prizes listed are the estimated retail value and claimed in USDC. You can easily purchase Ethereum for the network fee or exchange your USDC winnings for your local currency on a variety of exchanges such as Binance, Coinbase or even directly within your wallet.`
    },
    {
      question: "Can I play on mobile?",
      answer: `Yes, you can play on any device that supports an application.`,
    },
    {
      question: "What application are tournaments hosted on?",
      answer: `Our first season of tournaments will be hosted on the reputable ClubGG application (https://www.clubgg.net/). Future season tournaments will take place on the Tempus Poker application to be developed.`,
    },
  ];
  

const FAQ = () => {

    const [snackBarType, SetSnackBarType] = useState(SnackbarType.fail)
    const [snackBarMessage, SetSnackBarMessage] = useState("")
    const snackbarRef = useRef(null);


    return(
        <div className='container2'>    
                {/* <AnimatedPage> */}
                <div className="background-home-section-banner">
                    <Navbar/>
                    <div className='section-titles'>
                        <div className='how-it-works-section-text'>
                            <div className='page-title'>            
                                <h5>FAQ</h5>
                            </div>
                        </div>
                    </div>
                    <div className='down-arrow'></div>
                </div>
                <div className='grey-bk-section'>
                    <div className='faq-container'>
                        <div className='faq-container-text'>
                            <h1>Membership FAQ</h1>
                            <div className='AccordionHolder'>
                                <Accordion questionsAnswers={MembershipQuestionsAnswers} />
                            </div>
                        </div>
                        <div className='faq-container-text'>
                            <h1>Tournament FAQ</h1>
                            <div className='AccordionHolder'>
                                <Accordion questionsAnswers={tournamentQuestionsAnswers} />
                            </div>
                        </div>
                    </div>
                </div>
                {/* </AnimatedPage> */}
            <div className="background-home-section-banner">
            <div className='footer-section'>
                <Footer/> 
            </div>
            </div>
        </div>
    
    )
}

export default FAQ;