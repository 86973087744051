import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Home from "../pages/home/home";
import Partner1 from "../pages/home/AffiliateMintPages/affiliatePages";
import Mint from '../pages/mint/mint';
import Poker from "../pages/poker/poker";
import Prizes from '../pages/prizes/prizes';
import FAQ from "../pages/FAQ/FAQ";
import Privacy from "../pages/T&C_Privacy/Privacy"
import TAndC from "../pages/T&C_Privacy/T&C"
import About from "../pages/About/about"

import { AnimatePresence } from 'framer-motion/dist/framer-motion';

function AnimatedRoutes() {
    const location = useLocation();

    return(
        <AnimatePresence exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
                <Route path="/" exact component={Home} />
                <Route path="/adFybH8E34Bs2RjyH" exact component={Partner1} />
                <Route path="/About" exact component={About}/>
                <Route path="/Poker" exact component={Poker}/>
                <Route path="/Prizes" exact component={Prizes}/>
                <Route path="/FAQ" exact component={FAQ}/>
                <Route path="/Privacy" exact component={Privacy}/>
                <Route path="/TermsAndConditions" exact component={TAndC}/>
            </Switch>
        </AnimatePresence>
    );
}

export default AnimatedRoutes