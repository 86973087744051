export const MenuList = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "About",
    url: "/About",
  },
  {
    title: "Poker",
    url: "/Poker",
  },
  {
    title: "Prizes",
    url: "/Prizes",
  },
  {
    title: "FAQ",
    url: "/FAQ",
  }
];
